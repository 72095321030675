.span {
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  display: inline-block;
  margin: 0 8px;
  padding: 5px;
}

.visitor {
  width: 100%;
  text-align: left;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px #ccc;
  padding: 10px;
  margin: 10px auto;
  box-sizing: border-box;
}
.dur-logo {
  height: 30px !important;
  width: 30px !important;
}
#nav-logo > .dur-logo {
  top: -10px;
  float: left;
  width: 30px;
  text-align: center;
  margin-left: 23px;
  margin-right: 15px;
  position: relative;
}

.h3 {
  text-align: left;
  margin-left: 10%;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.Button {
  background-color: transparent;
  float: right;
  border: none;
  color: blue;
  outline: none;
  cursor: pointer;
  font: inherit;
  margin: 10px;
  font-weight: bold;
  font-size: medium;
  /* text-align: center; */
}

.Button:first-of-type {
  margin-left: 0;
  padding-left: 0;
}
.Button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.Success {
  color: #5c9210;
}

.Danger {
  color: #944317;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
